import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`ArF scanners`}</h4>
    <p>{`ArF scanners and ArF immersion scanners are among the most advanced photolithography equipment currently used in mass semiconductor production, second only to EUV scanners. EUV and ArF immersion scanners are the only lithography tools capable of mass-producing chips at near-cutting edge scale (the absolute cutting edge requires EUV). Only the Dutch firm ASML and the Japanese firm Nikon produce ArF scanners or ArF immersion scanners capable of mass production.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      